import { Injectable } from '@angular/core';
import {Subject} from "rxjs";

export interface ModalState {
  content: string;
  submitAction: () => void;
}

@Injectable({
  providedIn: 'root'
})
export class ActionConfirmationModalService {

  private modalStateSource = new Subject<ModalState | null>();
  modalState$ = this.modalStateSource.asObservable();

  constructor() { }

  showModal(content: string, submitAction: () => void) {
    this.modalStateSource.next({ content, submitAction });
  }

  closeModal() {
    this.modalStateSource.next(null);
  }
}
